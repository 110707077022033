

.App {
  margin:3rem;
  margin-left: 10rem;
  margin-right: 10rem;
  font-weight:450;
  text-align: center;
}

.box{
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 5px;
  background-color: lightpink;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);


}

.record{
  transform:scale(1);
  position:fixed;
  height:4rem;
  width:4rem;
  background-color: white;
  border-radius: 50%;
  top:87%;
  left:48.5%;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.4);
  transition: 1s;

}

.small{
transform:scale(0.9)
}

.record:hover{
  transform: scale(0.9) ;
  opacity:0.5;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
